import useMount from 'hooks/useMount';

function Refresh() {
  useMount(() => {
    window.location.reload();
  });

  return null;
}

export default Refresh;
